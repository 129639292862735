import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p11galloAudio from "../audio/p11galloAudio.m4a"
import p11kikirikiAudio from "../audio/p11kikirikiAudio.m4a"
import p11VO from "../audio/p11VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page11 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p08_cencerro.png"],
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
    if(x===2){
      if(!this.state.active){
        audioEl.play()
      }
      else{ audioEl.pause();audioEl.currentTime = 0; }
  }
  else {
    audioEl.play()
  }


  }
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]})}, 1000 );
      }
      else { this.setState({cencerro: images["p11_cencerro.png"]}); }
    })

}
  render() {
    return (
      <div className="main-container">
      <Link to="/Page10">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page12">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p11_back.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p11Cuentamelo"  onClick={() => this.playAudio(0)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className="_idContainer163">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_gallo.png"]} alt="" />
          </div>
          <div className="_idContainer164">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_galloCola.png"]} alt="" />
          </div>
          <div className="_idContainer166">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_galloCabeza.png"]} alt="" />
            <div className="_idContainer167">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_picoTop.png"]} alt="" />
            </div>
            <div className={"_idContainer165" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_picoBott.png"]} alt="" />
            </div>
          </div>
          <div className="_idContainer168">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_sombraPaja.png"]} alt="" />
          </div>
          <div className={"_idContainer169" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_kikiriki.png"]} alt="" />
          </div>
          <div className={"_idContainer170" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_nota06.png"]} alt="" />
          </div>
          <div className={"_idContainer171" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_nota05.png"]} alt="" />
          </div>
          <div className={"_idContainer172" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_nota04.png"]} alt="" />
          </div>
          <div className={"_idContainer173" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_nota03.png"]} alt="" />
          </div>
          <div className={"_idContainer174" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_nota02.png"]} alt="" />
          </div>
          <div className={"_idContainer175" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p11_nota01.png"]} alt="" />
          </div>
          <div className="_idContainer176 Basic-Text-Frame">
            <span>Todo esto en lo que canta un gallo.</span>
          </div>
          <div className="_idContainer177" onClick={()=> this.playAudio(1)}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4266.png"]} alt="" />
          </div>
          <div className={"_idContainer178" + (this.state.active ? " active" : "")} onClick={() => {this.handleCencerroClick(); this.playAudio(2)}}>
            <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
          </div>
          <audio className="audio-element">
            <source src={p11VO}></source>
          </audio>
          <audio className="audio-element">
            <source src={p11galloAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p11kikirikiAudio}></source>
          </audio>
        </div>
      <Footer />
      </div>
    )
  }
  }

export default Page11;
